var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        { attrs: { title: _vm.$t("lbl_invoice_ar") } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: {
                model: _vm.formState,
                "label-align": "left",
                "wrapper-col": { span: 12 },
                "label-col": { span: 8 }
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.onSubmit.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "a-row",
                { attrs: { type: "flex", gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: _vm.$t("lbl_branch"), prop: "branch" }
                        },
                        [
                          _c("a-select", {
                            attrs: {
                              "allow-clear": "",
                              "show-search": "",
                              loading: _vm.loading.branch,
                              "data-testid": "trucking-ar-invoice-branch",
                              "filter-option": false,
                              options: _vm.branchOptions,
                              placeholder: _vm.$t("common.select-text", {
                                text: _vm.$t("lbl_branch")
                              }),
                              "label-in-value": ""
                            },
                            on: { search: _vm.onSearchBranch },
                            model: {
                              value: _vm.formState.branch,
                              callback: function($$v) {
                                _vm.$set(_vm.formState, "branch", $$v)
                              },
                              expression: "formState.branch"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_customer"),
                            prop: "customer"
                          }
                        },
                        [
                          _c("a-select", {
                            attrs: {
                              "allow-clear": "",
                              "show-search": "",
                              "data-testid": "trucking-ar-invoice-customer",
                              loading: _vm.loading.customer,
                              "filter-option": false,
                              options: _vm.customerOptions,
                              placeholder: _vm.$t("common.select-text", {
                                text: _vm.$t("lbl_customer")
                              }),
                              "dropdown-match-select-width": false,
                              "label-in-value": ""
                            },
                            on: { search: _vm.onSearchCustomer },
                            model: {
                              value: _vm.formState.customer,
                              callback: function($$v) {
                                _vm.$set(_vm.formState, "customer", $$v)
                              },
                              expression: "formState.customer"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_invoice_date"),
                            prop: "invoiceDate"
                          }
                        },
                        [
                          _c("a-range-picker", {
                            attrs: {
                              "allow-clear": "",
                              "data-testid": "trucking-ar-invoice-invoice-date",
                              placeholder: [
                                _vm.$t("lbl_start_date"),
                                _vm.$t("lbl_end_date")
                              ],
                              format: _vm.DEFAULT_DATE_FORMAT
                            },
                            model: {
                              value: _vm.formState.invoiceDate,
                              callback: function($$v) {
                                _vm.$set(_vm.formState, "invoiceDate", $$v)
                              },
                              expression: "formState.invoiceDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_invoice_number"),
                            prop: "invoice"
                          }
                        },
                        [
                          _c("a-select", {
                            attrs: {
                              "allow-clear": "",
                              "data-testid":
                                "trucking-ar-invoice-invoice-number",
                              "show-search": "",
                              loading: _vm.loading.invoice,
                              "filter-option": false,
                              options: _vm.invoiceOptions,
                              placeholder: _vm.$t("common.select-text", {
                                text: _vm.$t("lbl_invoice_number")
                              }),
                              "dropdown-match-select-width": false,
                              "label-in-value": ""
                            },
                            on: { search: _vm.onSearchInvoice },
                            model: {
                              value: _vm.formState.invoice,
                              callback: function($$v) {
                                _vm.$set(_vm.formState, "invoice", $$v)
                              },
                              expression: "formState.invoice"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: _vm.$t("lbl_status"), prop: "status" }
                        },
                        [
                          _c("a-select", {
                            attrs: {
                              "allow-clear": "",
                              "data-testid": "trucking-ar-invoice-status",
                              loading: _vm.loading.status,
                              options: _vm.statusOptions,
                              placeholder: _vm.$t("common.select-text", {
                                text: _vm.$t("lbl_status")
                              })
                            },
                            model: {
                              value: _vm.formState.status,
                              callback: function($$v) {
                                _vm.$set(_vm.formState, "status", $$v)
                              },
                              expression: "formState.status"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                {
                  attrs: {
                    type: "flex",
                    justify: "space-between",
                    gutter: [16, 16]
                  }
                },
                [
                  _c(
                    "a-col",
                    [
                      _c(
                        "a-space",
                        [
                          _c(
                            "a-button",
                            {
                              attrs: {
                                "html-type": "button",
                                "data-testid": "trucking-ar-invoice-reset"
                              },
                              on: { click: _vm.handleReset }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")]
                          ),
                          _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.loading.find,
                                "data-testid": "trucking-ar-invoice-find",
                                "html-type": "submit"
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.$can("create", "trucking-invoice-ar")
                    ? _c(
                        "a-col",
                        [
                          _c(
                            "RouterLink",
                            {
                              attrs: {
                                to: {
                                  name: "trucking.account-receivable.create"
                                }
                              }
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    "data-testid": "trucking-ar-invoice-create",
                                    type: "primary",
                                    "html-type": "button"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_create_new")) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c("a-table", {
            staticClass: "mt-2",
            attrs: {
              "data-source": _vm.invoices.data,
              columns: _vm.columns,
              "row-key": "id",
              size: "small",
              loading: _vm.loading.find,
              scroll: { x: 1800 },
              "data-testid": "trucking-ar-invoice-table",
              pagination: {
                showTotal: function() {
                  return _vm.$t("lbl_total_items", {
                    total: _vm.invoices.totalElements
                  })
                },
                total: _vm.invoices.totalElements,
                showSizeChanger: true,
                pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
                current: _vm.pagination.page,
                defaultPageSize: _vm.pagination.limit
              },
              "row-class-name": function(_record, index) {
                return index % 2 ? "bg-white" : "bg-gray-light"
              },
              "row-selection": {
                selectedRowKeys: _vm.selectedRowKeys,
                onChange: _vm.onSelectChange,
                onSelect: _vm.onSelectToggle,
                onSelectAll: _vm.onSelectAllRecord,
                getCheckboxProps: function(record) {
                  return {
                    props: {
                      disabled: _vm.isNotAllowToPrintReceipt(record.status)
                    }
                  }
                }
              }
            },
            on: { change: _vm.onChangeTable },
            scopedSlots: _vm._u([
              {
                key: "journal",
                fn: function(text, ref) {
                  var journalNo = ref.journalNo
                  var journalId = ref.journalId
                  return [
                    journalId
                      ? _c(
                          "RouterLink",
                          {
                            staticClass: "text-link",
                            attrs: {
                              to: {
                                name: "generaljournal.detail",
                                params: { id: journalId }
                              },
                              target: "_blank",
                              "data-testid": "trucking-ar-invoice-journal"
                            }
                          },
                          [_vm._v(" " + _vm._s(journalNo) + " ")]
                        )
                      : _c("span", [_vm._v(_vm._s("-"))])
                  ]
                }
              },
              {
                key: "documentNumber",
                fn: function(text, ref) {
                  var id = ref.id
                  var documentNumber = ref.documentNumber
                  return [
                    _c(
                      "a",
                      {
                        staticClass: "text-link",
                        attrs: {
                          "data-testid": "trucking-ar-invoice-document-number"
                        },
                        on: {
                          click: function($event) {
                            return _vm.viewSummary(id)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(documentNumber) + " ")]
                    )
                  ]
                }
              },
              {
                key: "action",
                fn: function(text, record) {
                  return [
                    _c(
                      "RouterLink",
                      {
                        staticClass: "text-link",
                        attrs: {
                          to: {
                            name: _vm.getRoute(record.status),
                            params: { id: record.id }
                          },
                          "data-testid": "trucking-ar-invoice-view"
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("lbl_view")) + " ")]
                    )
                  ]
                }
              }
            ])
          }),
          _c(
            "a-row",
            { staticClass: "mt-3", attrs: { type: "flex", justify: "end" } },
            [
              _c(
                "a-col",
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled: _vm.selectedRowKeys.length === 0,
                            loading: _vm.loading.posting,
                            "data-testid": "trucking-ar-invoice-posting"
                          },
                          on: { click: _vm.validatePosting }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_posting")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled: _vm.selectedRowKeys.length === 0,
                            loading: _vm.loading.print,
                            "data-testid": "trucking-ar-invoice-print-receipt"
                          },
                          on: { click: _vm.validatePrintReceipt }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("lbl_print_receipt_document")) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("InvoiceSummaryModal", {
        attrs: {
          "data-testid": "trucking-ar-invoice-summary-modal",
          "invoice-id": _vm.modalSummary.invoiceId
        },
        model: {
          value: _vm.modalSummary.show,
          callback: function($$v) {
            _vm.$set(_vm.modalSummary, "show", $$v)
          },
          expression: "modalSummary.show"
        }
      }),
      _c("InvoicePostingModal", {
        attrs: {
          "data-testid": "trucking-ar-invoice-posting-modal",
          invoices: _vm.modalPosting.data
        },
        on: { approved: _vm.handleApproved },
        model: {
          value: _vm.modalPosting.show,
          callback: function($$v) {
            _vm.$set(_vm.modalPosting, "show", $$v)
          },
          expression: "modalPosting.show"
        }
      }),
      _c("InvoicePrintReceiptModal", {
        attrs: {
          "data-testid": "trucking-ar-invoice-print-receipt-modal",
          "invoice-ids": _vm.modalPrint.data
        },
        on: { approved: _vm.handleApproved },
        model: {
          value: _vm.modalPrint.show,
          callback: function($$v) {
            _vm.$set(_vm.modalPrint, "show", $$v)
          },
          expression: "modalPrint.show"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }